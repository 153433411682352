<template>
  <component
    :is="tag"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    role="dialog"
    aria-modal="true"
  >
    <slot />
  </component>
</template>

<script>
import { backdrop } from "@/js/util/modal";

export default {
  name: "ModalBackdropDialog",
  inject: { backdrop },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    ariaLabelledby: {
      type: String,
      default: null
    },
    ariaLabel: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      closedActiveElement: undefined
    };
  },
  created() {
    if (process.env.NODE_ENV !== "production") {
      if (!this.ariaLabelledby && !this.ariaLabel) {
        // eslint-disable-next-line no-console
        console.error(
          "[<modal-dialog> warn]: The dialog has either:\n" +
            " - A value set for the aria-labelledby property that refers to a visible dialog title.\n" +
            " - A label specified by aria-label.\n" +
            "See https://www.w3.org/TR/wai-aria-practices/#dialog_roles_states_props for more information."
        );
      }
    }

    this.$on("focus-first-descendant", this.focusFirstDescendant);
    this.$on("focus-last-descendant", this.focusLastDescendant);

    this.backdrop.$emit("create:dialog", this);
  },
  methods: {
    focusFirstDescendant(el = this.$el) {
      return Array.from(el.children).find(
        child => this.attemptFocus(child) || this.focusFirstDescendant(child)
      );
    },
    focusLastDescendant(el = this.$el) {
      return Array.from(el.children)
        .reverse()
        .find(
          child => this.attemptFocus(child) || this.focusLastDescendant(child)
        );
    },
    attemptFocus(el) {
      el.focus();

      return document.activeElement === el;
    }
  }
};
</script>
