<script>
import { bus, backdrop } from "@/js/util/modal";

export default {
  name: "ModalBackdrop",
  provide() {
    return { [backdrop]: this };
  },
  props: {
    id: {
      type: String,
      required: true
    },
    transition: {
      type: String,
      default: "transition"
    },
    transitionProps: {
      type: Object,
      default: () => ({})
    },
    initiallyOpen: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "show",
      validate(value) {
        return ["if", "show"].includes(value);
      }
    },
    tag: {
      type: String,
      default: "div"
    },
    closedFocus: {
      type: [HTMLElement, String],
      default: undefined
    }
  },
  data() {
    return {
      dialog: undefined,
      open: false,
      activeElement: undefined
    };
  },
  created() {
    this.$on("create:dialog", this.createDialog);
    bus.$on("toggle", this.maybeToggle);
  },
  mounted() {
    if (this.initiallyOpen) {
      this.toggle();
    }
  },
  methods: {
    createDialog(dialog) {
      this.dialog = dialog;
    },
    maybeToggle(controls) {
      if (!controls.split(" ").includes(this.id)) {
        return;
      }

      this.toggle();
    },
    toggle() {
      this.$emit("before-toggle");
      this.$emit(this.open ? "before-close" : "before-open");

      this.open = !this.open;

      if (this.open) {
        if (document.body.classList.contains("prevent-scroll")) {
          document.body.classList.add("prevent-scroll-2");
        } else {
          document.body.classList.add("prevent-scroll");
        }
      } else {
        if (document.body.classList.contains("prevent-scroll-2")) {
          document.body.classList.remove("prevent-scroll-2");
        } else {
          document.body.classList.remove("prevent-scroll");
        }
      }

      if (!this.open) {
        this.$nextTick(() => {
          const el = document.querySelector(this.closedFocus);

          if (el) {
            el.focus();

            return;
          }

          this.activeElement.focus();
          this.$emit("closed");
          this.$emit("toggled");
        });

        return;
      }

      this.activeElement = document.activeElement;

      this.$nextTick(() => {
        this.dialog.$emit("focus-first-descendant");
        this.$emit("opened");
        this.$emit("toggled");
      });
    },
    createFocusTrap(createElement, position) {
      return createElement("div", {
        attrs: { tabindex: 0 },
        on: {
          focus: () => this.dialog.$emit(`focus-${position}-descendant`)
        }
      });
    }
  },
  render(createElement) {
    const data = {
      attrs: { id: this.id },
      on: {
        keyup: ({ keyCode }) => {
          if (keyCode !== 27) {
            return;
          }

          this.toggle();
        }
      }
    };

    if (this.mode === "show") {
      data.directives = [
        {
          name: "show",
          value: this.open
        }
      ];
    }

    const children = [
      this.createFocusTrap(createElement, "last"),
      ...this.$slots.default,
      this.createFocusTrap(createElement, "first")
    ];
    const el =
      this.mode === "show" || this.open
        ? createElement(this.tag, data, children)
        : undefined;

    if (this.transition) {
      return createElement(
        this.transition,
        {
          props: this.transitionProps
        },
        [el]
      );
    }

    return el;
  }
};
</script>
