<template>
  <GlidePosition :callback="update" :interval="100">
    <slot />
  </GlidePosition>
</template>

<script>
export default {
  name: "GlideParallax",
  data() {
    return {
      currentTime: null
    };
  },
  watch: {
    currentTime(val) {
      this.$el.style.transform = `translateY(calc(${val} * 20%))`;
    }
  },
  methods: {
    update({ outerHeight }) {
      this.currentTime = 0.5 - outerHeight;
    }
  }
};
</script>
