<template>
  <button @click="click">
    <slot />
  </button>
</template>

<script>
import { bus } from "@/js/util/modal";

export default {
  name: "ModalButton",
  props: {
    controls: {
      type: String,
      required: true
    }
  },
  methods: {
    click() {
      bus.$emit("toggle", this.controls);
    }
  }
};
</script>
