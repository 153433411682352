import Vue from "vue";

// SASS
import "./sass/app.scss";

// Post CSS
import "postcss-object-fit-images";

// Pollyfills
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images";

// Glide
import GlideRect from "@/components/GlideRect.vue";
import GlidePosition from "@/components/GlidePosition.vue";
import GlideParallax from "@/components/GlideParallax.vue";
import GlideVisibility from "@/components/GlideVisibility.vue";
import GlideIntersection from "@/components/GlideIntersection.vue";

// Plyr
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

// Plyr Video iframe
import VideoIframe from "@/components/VideoIframe.vue";
import VideoThumbnail from "@/components/VideoThumbnail.vue";

// Modal
import ModalLink from "@/components/ModalLink.vue";
import ModalButton from "@/components/ModalButton.vue";
import ModalBackdrop from "@/components/ModalBackdrop.vue";
import ModalBackdropDialog from "@/components/ModalBackdropDialog.vue";

// Intersection Observer Polyfill
import "intersection-observer";

Vue.config.productionTip = false;

Vue.use(VuePlyr);

svg4everybody();
objectFitImages();

const Components = {
  ModalLink,
  ModalButton,
  ModalBackdrop,
  ModalBackdropDialog,
  VideoIframe,
  VideoThumbnail,
  GlideRect,
  GlidePosition,
  GlideParallax,
  GlideVisibility,
  GlideIntersection
};

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName]);
});

new Vue().$mount("#app");
