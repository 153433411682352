<template>
  <GlideIntersection
    :callback="handleCallback"
    class="video-container"
    ref="container"
  >
    <VideoIframe
      :video-url="videoUrl"
      :video-state="videoState"
      video-stop="pause"
      :options="{
        muted: true,
        controls: [],
        autopause: true,
        clickToPlay: false,
        loop: { active: true },
        fullscreen: { enabled: false, fallback: false, iosNative: false }
      }"
      :style="scale"
    />
  </GlideIntersection>
</template>

<script>
import { backdrop } from "@/js/util/modal";

export default {
  provide() {
    return { [backdrop]: null };
  },
  mounted() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    scale() {
      const aspect = this.width / this.height;
      const targetAspect = 16 / 9;
      const scale =
        aspect / targetAspect > 1
          ? aspect / targetAspect * 1.05
          : targetAspect / aspect * 1.05;

      return { transform: `scale(${scale})` };
    }
  },
  props: {
    videoUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      videoState: undefined,
      width: undefined,
      height: undefined
    };
  },
  methods: {
    handleCallback({ isIntersecting }) {
      this.videoState = isIntersecting;
    },
    handleResize() {
      const {
        width,
        height
      } = this.$refs.container.$el.getBoundingClientRect();

      this.width = width;
      this.height = height;
    }
  }
};
</script>

<style>
.video-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.video-container .plyr--video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.video-container .plyr__video-wrapper {
  width: 100%;
}
</style>
