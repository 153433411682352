<script>
import render from "../js/mixins/render";

export default {
  name: "GlideRect",
  mixins: [render],
  props: {
    callback: {
      type: Function,
      default: null
    },
    interval: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      time: performance.now()
    };
  },
  watch: {
    callback(callback, oldCallback) {
      if (callback && oldCallback) {
        return;
      }

      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      window.requestAnimationFrame(this.update);
    },
    update() {
      const { callback } = this;

      if (!callback) {
        return;
      }

      const now = performance.now();
      const { interval, init } = this;

      if (interval && now - this.time < interval) {
        init();

        return;
      }

      this.time = now;

      callback(this.$el.getBoundingClientRect());
      init();
    }
  }
};
</script>
