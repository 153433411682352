<template>
  <vue-plyr ref="plyr" :options="options">
    <div class="video-container">
      <iframe
        allowfullscreen
        allowtransparency
        :src="computedUrl"
      />
    </div>
  </vue-plyr>
</template>

<script>
import { backdrop } from "@/js/util/modal";

export default {
  inject: { backdrop },
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    videoState: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    videoStop: {
      type: String,
      default: "stop"
    }
  },
  mounted() {
    this.player.on("ready", () => {
      if (!this.options) {
        return;
      }
      this.player.muted = true;

      this.handleVideoState();
    });
  },
  computed: {
    player() {
      return this.$refs.plyr.player;
    },
    computedUrl() {
      const { type, id } = this.parseVideo(this.videoUrl);

      if (type === "youtube") {
        return `https://www.youtube.com/embed/${id}?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1`;
      } else if (type === "vimeo") {
        return `https://player.vimeo.com/video/${id}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`;
      }

      return null;
    }
  },
  watch: {
    videoState: "handleVideoState",
    "backdrop.open": "handleVideoState"
  },
  methods: {
    parseVideo(url) {
      let type = "";
      url.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
      );

      if (RegExp.$3.indexOf("youtu") > -1) {
        type = "youtube";
      } else if (RegExp.$3.indexOf("vimeo") > -1) {
        type = "vimeo";
      }

      return {
        type: type,
        id: RegExp.$6
      };
    },
    handleVideoState(state = true) {
      if (this.videoStop === "stop") {
        return state ? this.player.play() : this.player.stop();
      }

      return state ? this.player.play() : this.player.pause();
    }
  }
};
</script>
