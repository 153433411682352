var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GlideIntersection',{ref:"container",staticClass:"video-container",attrs:{"callback":_vm.handleCallback}},[_c('VideoIframe',{style:(_vm.scale),attrs:{"video-url":_vm.videoUrl,"video-state":_vm.videoState,"video-stop":"pause","options":{
      muted: true,
      controls: [],
      autopause: true,
      clickToPlay: false,
      loop: { active: true },
      fullscreen: { enabled: false, fallback: false, iosNative: false }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }