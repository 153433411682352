<script>
import { bus } from "@/js/util/modal";

export default {
  name: "ModalLink",
  props: {
    controls: {
      type: String,
      required: true
    },
    scrollPosition: {
      type: String,
      default: "start"
    }
  },
  render(createNode) {
    return createNode(
      "a",
      {
        on: {
          click: () => {
            bus.$emit("toggle", this.controls);
            setTimeout(() => {
              window.scrollBy({
                top: -document
                  .getElementById("header__secondary")
                  .getBoundingClientRect().height
              });
            }, 10);
          }
        }
      },
      this.$slots.default
    );
  }
};
</script>
